import React from 'react';
import ImageGallery from 'react-image-gallery';

/*
 * 
 * https://www.npmjs.com/package/react-image-gallery
 * 
 */

const GalleryPanel = (props) => {
    const images = []
    props.pictures.map(pic => (
        images.push({
            'original' : pic.path,
            'thumbnail' : pic.thumbnail,
            'description' : pic.caption,
        })
    ));

    
    return ( 
        <div className='Gallery'>
            <ImageGallery items={images} thumbnailPosition='top' lazyLoad={true} showFullscreenButton={false} autoPlay={true} slideInterval={6000}/>
        </div>
        
    );
}
 
export default GalleryPanel;