import React from 'react';
import './App.css';
import {
  Route
} from "react-router-dom";
import Home from './components/Home';
import Contact from './components/contact/Contact';
import GalleryWithoutFilters from './components/GalleryWithoutFilters';
import AviationRepo from './resources/repos/AviationRepo'
import DCRepo from './resources/repos/DCRepo'


function App() {
  return (
    <div className="App">
      <Route
        exact path="/"
        component={Home}
      />
      <Route
        exact path="/contact"
        component={Contact}
      />
      <Route
        exact path="/aviation"
        render={(props) => (
          <GalleryWithoutFilters {...props} name="Aviation" desc="“When once you have tasted flight, you will forever walk the earth with your eyes turned skyward, for there you have been, and there you will always long to return.”
          – Leonardo DaVinci" repo={AviationRepo} />
        )}
      />
      <Route
        exact path="/dc"
        render={(props) => (
          <GalleryWithoutFilters {...props} name="Washington, D.C." desc="The nation's capital" repo={DCRepo} />
        )}
      />
    </div>
  );
}

export default App;
