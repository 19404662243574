import React, { Component } from 'react';
import GalleryPanel from './GalleryPanel';
import FilterPanel from './filter/FilterPanel';






class GalleryWithFilters extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            filter: ""
         }

         this.pictures = props.repo.pictures;
         this.getPicturesByTag.bind(this);
         this.getTags.bind(this);
    }
    
    filterHandler = (newFilter) => {
        this.setState({
            filter: newFilter
        });
    }


    getPicturesByTag(tag) {
        return this.pictures.filter(picture => picture.tags.includes(tag) || !tag)   
    }

    getTags() {
        var dedupedTags = []
        this.pictures.forEach(pic => {
            pic.tags.forEach(tag => {
                if(!dedupedTags.includes(tag)) {
                   dedupedTags.push(tag)
                }
            })
        });
        return dedupedTags.sort();
    }
    
    render() { 
        return (  
            <div>
                <FilterPanel tags={this.getTags()} activeFilter={this.state.filter} filterHandler={this.filterHandler}/>
                <GalleryPanel pictures={this.getPicturesByTag(this.state.filter)} />
            </div>
        );
    }
}
 
export default GalleryWithFilters;