import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faFlickr, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faEnvelopeOpen } from '@fortawesome/free-regular-svg-icons'
import EmailForm from './EmailForm';
import { Button } from 'react-bootstrap';
import NavbarComponent from '../NavbarComponent';

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            showForm: false
        }
        
    }

    toggleForm = () => {
        var form = this.state.showForm;
        this.setState({
            showForm: !form
        });
    }



    render() { 
        return (
            <div>
                <NavbarComponent />
                <h1>Mark Clancy</h1>
                <h3>Aviation and Travel Photographer based in Boston, MA</h3>
                <h3>Contact:</h3>
                <div className='Contact-buttons'>
                    <Button variant= 'outline-light' size='lg' onClick={()=> window.open("https://www.instagram.com/markymark_cl/", "_blank")}><FontAwesomeIcon icon={faInstagram} /> Instagram</Button>
                    <br />
                    <Button variant= 'outline-light' size='lg' onClick={()=> window.open("https://www.flickr.com/people/190180182@N07/", "_blank")}><FontAwesomeIcon icon={faFlickr} /> Flickr</Button>
                    <br />
                    <Button variant= 'outline-light' size='lg' onClick={()=> window.open("https://twitter.com/markwclancy/", "_blank")}><FontAwesomeIcon icon={faTwitter} /> Twitter</Button>
                    <br />
                    <Button variant='outline-light' size='lg' active={this.state.showForm} onClick={this.toggleForm.bind(this)}><FontAwesomeIcon icon={faEnvelopeOpen} /> Email</Button>
                </div>
                {(this.state.showForm) ? <EmailForm /> : ''}
            </div>
          );
    }
}
 
export default Contact;