import React from 'react';
import Button from 'react-bootstrap/Button'

const FilterButton = (props) => {

    const activate = () => {
        props.filterHandler(props.label)
    }

    return ( 
        // uses lambda to prevent infinite loop
        <Button onClick={() => activate()} variant={props.active ? 'light' : 'outline-light'}>
            {props.label}
        </Button>
    );
}
 
export default FilterButton;