// All images copyright Mark Clancy

export default {
    pictures: [
        
        {
            path: "https://cdn.photos.markwclancy.com/photos/large/JeffersonFall.jpg",
            thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/JeffersonFall.jpg",
            caption: "Fall sunset at the Tidal Basin",
            tags: ["Cityscape"]
        },
        {
            path: "https://cdn.photos.markwclancy.com/photos/large/Capitol.jpg",
            thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/Capitol.jpg",
            caption: "Evening at the Capitol",
            tags: ["Cityscape"]
        },
        {
            path: "https://cdn.photos.markwclancy.com/photos/large/NationalMall.jpg",
            thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/NationalMall.jpg",
            caption: "Pink skies over the mall",
            tags: ["Cityscape"]
        },
        {
            path: "https://cdn.photos.markwclancy.com/photos/large/NationalCathedral.jpg",
            thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/NationalCathedral.jpg",
            caption: "Golden glow on the National Cathedral",
            tags: ["Cityscape"]
        },
        {
            path: "https://cdn.photos.markwclancy.com/photos/large/GreatFalls.jpg",
            thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/GreatFalls.jpg",
            caption: "Fall colors at Great Falls",
            tags: ["Landscape"]
        },
        {
            path: "https://cdn.photos.markwclancy.com/photos/large/AAWashingtonMonument.jpg",
            thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/AAWashingtonMonument.jpg",
            caption: "An American Airlines Airbus A320 rocketing out of Washington",
            tags: ["Aviation", "Cityscape"]
        },
        {
            path: "https://cdn.photos.markwclancy.com/photos/large/DCA-sunset.jpg",
            thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/DCA-sunset.jpg",
            caption: "Sunset at Washington National Airport",
            tags: ["Aviation", "Cityscape"]
        },
        {
            path: "https://cdn.photos.markwclancy.com/photos/large/IADMoon.jpg",
            thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/IADMoon.jpg",
            caption: "Moonrise over Washington Dulles",
            tags: ["Aviation", "Cityscape"]
        },
        {
            path: "https://cdn.photos.markwclancy.com/photos/large/MonumentHawk.jpg",
            thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/MonumentHawk.jpg",
            caption: "A U.S. Army UH-60 Blackhawk flying low past the Washington Monument",
            tags: ["Aviation", "Cityscape"]
        },
        
]
}