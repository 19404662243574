import React from 'react';
import GalleryWithFilters from './GalleryWithFilters';
import PicturesRepo from '../resources/repos/PicturesRepo'
import NavbarComponent from './NavbarComponent';


function Home() {
  return (
    <div className="App">
      <NavbarComponent />   
      <h1>Mark Clancy</h1>
      <h3>Aviation and Travel Photographer based in Boston, MA</h3>
      <GalleryWithFilters repo={PicturesRepo}/>
      <span className='Footer'>&copy; Mark Clancy</span>
    </div>
  );
}

export default Home;
