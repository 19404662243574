import React from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';

const NavbarComponent = (props) => {
    return (
    <Navbar collapseOnSelect expand="lg" bg="#030015" variant="dark">
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
                <Nav.Link href="/">Gallery</Nav.Link>
                <NavDropdown title="Collections">
                    <NavDropdown.Item href="/aviation">Aviation</NavDropdown.Item>
                    <NavDropdown.Item href="/dc">Washington D.C.</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link href='/contact'>Contact</Nav.Link>
            </Nav> 
        </Navbar.Collapse>
    </Navbar>);
}
 
export default NavbarComponent;