import React from 'react';
import emailjs from 'emailjs-com';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert';

export default function EmailForm() {
    const [show, setShow] = React.useState(false);
    const [showError, setShowError] = React.useState(false);

    function sendEmail(e) {
        e.preventDefault();
        const form = e.currentTarget;
        if(form.checkValidity() === false) {
            alert("Must include email address and message.");
            return;
        }
        emailjs.sendForm('zoho', 'template_NQd4ayws_clone', e.target, 'user_kwL1AXde2WWoFShqBURzX')
        .then((result) => {
            console.log(result.text);
            setShow(true);
        }, (error) => {
            console.log(error.text);
            setShowError(true);
        });
    }

  return (
    <div className="Contact-form">
        <Form noValidate className="Contact-form" onSubmit={sendEmail}>
            <Form.Label>Name:</Form.Label>
            <Form.Control type="text" name="from_name" />
            <Form.Label>Email:</Form.Label>
            <Form.Control required type="email" name="reply_to" />
            <Form.Label>Message:</Form.Label>
            <Form.Control required as="textarea" rows="3" name="message_html" />
            <Button variant="light" type="submit">Send</Button>
        </Form>
        <Alert show={show} onClose={() => setShow(false)} variant='success' dismissible>Email Sent</Alert>
        <Alert show={showError} onClose={() => setShowError(false)} variant='danger' dismissible>Unable To Send Message!</Alert>
    </div>
  );
}