// All images copyright Mark Clancy

export default {
    pictures: [
        
    
        {
            path: "https://cdn.photos.markwclancy.com/photos/large/RaptorDemo.jpg",
            thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/RaptorDemo.jpg",
            caption: "The USAF F-22 Raptor Demo Team pulling Gs",
            tags: ["Aviation"]
        },
        {
            path: "https://cdn.photos.markwclancy.com/photos/large/TbirdSolos.jpg",
            thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/TbirdSolos.jpg",
            caption: "The USAF Thunderbird Solos",
            tags: ["Aviation"]
        },
        {
            path: "https://cdn.photos.markwclancy.com/photos/large/IAD_Dreamliner.jpg",
            thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/IAD_Dreamliner.jpg",
            caption: "A United Airlines Boeing 787-10 Dreamliner departing in front of Dulles' iconic Saarinen designed terminal",
            tags: ["Aviation"]
        },
        {
            path: "https://cdn.photos.markwclancy.com/photos/large/MaceHighAlphaPass.jpg",
            thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/MaceHighAlphaPass.jpg",
            caption: "Thunderbird 5 performing the high alpha pass",
            tags: ["Aviation"]
        },
        {
            path: "https://cdn.photos.markwclancy.com/photos/large/CoastGuardDemo.jpg",
            thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/CoastGuardDemo.jpg",
            caption: "A U.S. Coast Guard MH-65 crew perforing a SAR demo",
            tags: ["Aviation"]
        },
        {
            path: "https://cdn.photos.markwclancy.com/photos/large/SuperHornetBreak.jpg",
            thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/SuperHornetBreak.jpg",
            caption: "A VFA-105 'Gunslingers' F/A-18E Super Hornet in the overhead break",
            tags: ["Aviation"]
        },
        {
            path: "https://cdn.photos.markwclancy.com/photos/large/DCA-sunset.jpg",
            thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/DCA-sunset.jpg",
            caption: "Sunset at Washington National Airport",
            tags: ["Aviation", "Cityscape"]
        },
        {
            path: "https://cdn.photos.markwclancy.com/photos/large/AAWashingtonMonument.jpg",
            thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/AAWashingtonMonument.jpg",
            caption: "An American Airlines Airbus A320 rocketing out of Washington",
            tags: ["Aviation", "Cityscape"]
        },
        {
            path: "https://cdn.photos.markwclancy.com/photos/large/F35.jpg",
            thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/F35.jpg",
            caption: "A U.S. Air Force F-35A Lightning II",
            tags: ["Aviation"]
        },
        {
            path: "https://cdn.photos.markwclancy.com/photos/large/CheckmatesBW.jpg",
            thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/CheckmatesBW.jpg",
            caption: "A VFA-211 'Fighting Checkmates' F/A-18E Super Hornet landing at NAS Oceana",
            tags: ["Aviation"]
        },
        {
            path: "https://cdn.photos.markwclancy.com/photos/large/MonumentHawk.jpg",
            thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/MonumentHawk.jpg",
            caption: "A U.S. Army UH-60 Blackhawk flying low past the Washington Monument",
            tags: ["Aviation", "Cityscape"]
        },
        {
            path: "https://cdn.photos.markwclancy.com/photos/large/Chinooks.jpg",
            thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/Chinooks.jpg",
            caption: "A pair of CH-47 Chinooks performing a flyover of the National Mall",
            tags: ["Aviation", "Cityscape"]
        },
        {
            path: "https://cdn.photos.markwclancy.com/photos/large/ExpoA380.jpg",
            thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/ExpoA380.jpg",
            caption: "An Emirates Airbus A380 landing in golden light",
            tags: ["Aviation"]
        },
        {
            path: "https://cdn.photos.markwclancy.com/photos/large/ThunderbirdsDiamond.jpg",
            thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/ThunderbirdDiamond.jpg",
            caption: "The U.S. Air Force Thunderbirds Diamond",
            tags: ["Aviation"]
        },
        {
            path: "https://cdn.photos.markwclancy.com/photos/large/BostonHuey.jpg",
            thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/BostonHuey.jpg",
            caption: "A U.S. Air Force 1st Helicopter Squadron UH-1N Huey flying up the Charles River",
            tags: ["Aviation"]
        },
        {
            path: "https://cdn.photos.markwclancy.com/photos/large/SpeedbirdA350.jpg",
            thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/SpeedbirdA350-thumbnail.jpg",
            caption: "A British Airways A350 landing in the late afternoon sun",
            tags: ["Aviation"]
        },
        {
            path: "https://cdn.photos.markwclancy.com/photos/large/DeltaA319.jpg",
            thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/DeltaA319.jpg",
            caption: "A Delta Airlines Airbus A319 departing Boston Logan",
            tags: ["Aviation"]
        },
        {
            path: "https://cdn.photos.markwclancy.com/photos/large/VC25.jpg",
            thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/VC25-thumbnail.jpg",
            caption: "A U.S. Air Force VC-25A perfoming a flyover of Washington, D.C.",
            tags: ["Aviation"]
        },
        {
            path: "https://cdn.photos.markwclancy.com/photos/large/GunslingersLanding.jpg",
            thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/GunslingersLanding.jpg",
            caption: "A VFA-105 'Gunslingers' F/A-18E on short final at NAS Oceana",
            tags: ["Aviation"]
        },
        {
            path: "https://cdn.photos.markwclancy.com/photos/large/AmericanA321.jpg",
            thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/AmericanA321.jpg",
            caption: "An American Airlines A321 NEO",
            tags: ["Aviation"]
        },
        {
            path: "https://cdn.photos.markwclancy.com/photos/large/RedArrows.jpg",
            thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/RedArrows.jpg",
            caption: "A Royal Air Force Red Arrows Hawk T1 on display at the Smithsonian Udvar-Hazy Center",
            tags: ["Aviation"]
        },
        
        {
            path: "https://cdn.photos.markwclancy.com/photos/large/AirFranceA380.jpg",
            thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/AirFranceA380.jpg",
            caption: "An Air France Airbus A380 landing in front of building clouds",
            tags: ["Aviation"]
        },
        {
            path: "https://cdn.photos.markwclancy.com/photos/large/BlueAngelsBW.jpg",
            thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/BlueAngelsBW.jpg",
            caption: "The U.S. Navy Blue Angels",
            tags: ["Aviation"]
        },
]
}