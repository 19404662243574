import React, { Component } from 'react';
import Button from 'react-bootstrap/Button'

import FilterButton from './FilterButton';


class FilterPanel extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            //activeFilter: props.activeFilter
         }
    }

    filterHandler = (newFilter) => {
        this.props.filterHandler(newFilter);
    }

    render() { 
        return (
            <div className='FilterPanel'>
                Select Category:{' '}                
                {this.props.tags.map(tag => (
                     <FilterButton label={tag} active={tag === this.props.activeFilter} filterHandler={this.filterHandler}/>   
                ))}
                <Button variant= 'outline-dark' active={this.props.activeFilter} onClick={() => this.filterHandler('')}>
                    Clear Filter
                </Button>
            </div>
        );
    }
}
 
export default FilterPanel;