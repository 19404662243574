import React from 'react';
import GalleryPanel from './GalleryPanel';
import NavbarComponent from './NavbarComponent';

const GalleryWithoutFilters = (props) => {
    return (
    <div className="GalleryPage">
        <NavbarComponent />
        <h1>{props.name}</h1>
        <p className="Description">{props.desc}</p>
        <GalleryPanel pictures={props.repo.pictures} />
        <span className='Footer'>&copy; Mark Clancy</span>
    </div>);
}
 
export default GalleryWithoutFilters;