// All images copyright Mark Clancy

export default {
        pictures: [
            {
                path: "https://cdn.photos.markwclancy.com/photos/large/AAWashingtonMonument.jpg",
                thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/AAWashingtonMonument.jpg",
                caption: "An American Airlines Airbus A320 rocketing out of Washington",
                tags: ["Aviation", "Cityscape"]
            },
            {
                path: "https://cdn.photos.markwclancy.com/photos/large/JeffersonFall.jpg",
                thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/JeffersonFall.jpg",
                caption: "Fall sunset at the Tidal Basin",
                tags: ["Cityscape"]
            },
            {
                path: "https://cdn.photos.markwclancy.com/photos/large/DCA-sunset.jpg",
                thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/DCA-sunset.jpg",
                caption: "Sunset at Washington National Airport",
                tags: ["Aviation", "Cityscape"]
            },
            {
                path: "https://cdn.photos.markwclancy.com/photos/large/IAD_Dreamliner.jpg",
                thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/IAD_Dreamliner.jpg",
                caption: "A United Airlines Boeing 787-10 Dreamliner departing in front of Dulles' iconic Saarinen designed terminal",
                tags: ["Aviation"]
            },
            {
                path: "https://cdn.photos.markwclancy.com/photos/large/TowerBridge.jpg",
                thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/TowerBridge.jpg",
                caption: "Sunset at London Tower Bridge",
                tags: ["Cityscape"]
            },
            {
                path: "https://cdn.photos.markwclancy.com/photos/large/PruSuperMoon.jpg",
                thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/PruSuperMoon.jpg",
                caption: "The 2021 'Pink Moon' super moon rising over the Prudential Tower in Boston",
                tags: ["Cityscape"]
            },
            {
                path: "https://cdn.photos.markwclancy.com/photos/large/RossmonsterMilkyWay.jpg",
                thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/RossmonsterMilkyWay.jpg",
                caption: "The Milky Way Galaxy over Yellowstone",
                tags: ["Landscape"]
            },
            {
                path: "https://cdn.photos.markwclancy.com/photos/large/RaptorDemo.jpg",
                thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/RaptorDemo.jpg",
                caption: "The USAF F-22 Raptor Demo Team pulling Gs",
                tags: ["Aviation"]
            },
            {
                path: "https://cdn.photos.markwclancy.com/photos/large/TbirdSolos.jpg",
                thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/TbirdSolos.jpg",
                caption: "The USAF Thunderbird Solos",
                tags: ["Aviation"]
            },
            {
                path: "https://cdn.photos.markwclancy.com/photos/large/EastieSunset.jpg",
                thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/EastieSunset.jpg",
                caption: "Sunset over Boston",
                tags: ["Cityscape", "Landscape"]
            },
            {
                path: "https://cdn.photos.markwclancy.com/photos/large/Erasmusbrug.jpg",
                thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/Erasmusbrug-thumbnail.jpg",
                caption: "Clouds building over the Erasmusbrug in Rotterdam",
                tags: ["Cityscape"]
            },
            {
                path: "https://cdn.photos.markwclancy.com/photos/large/Rotterdam.jpg",
                thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/Rotterdam.jpg",
                caption: "Rotterdam in motion",
                tags: ["Cityscape"]
            },
            {
                path: "https://cdn.photos.markwclancy.com/photos/large/BackBayGoldenHour.jpg",
                thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/BackBayGoldenHour.jpg",
                caption: "Golden glow on the Boston Back Bay skyline",
                tags: ["Cityscape"]
            },
            {
                path: "https://cdn.photos.markwclancy.com/photos/large/CheckmatesBW.jpg",
                thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/CheckmatesBW.jpg",
                caption: "A VFA-211 'Fighting Checkmates' F/A-18E Super Hornet landing at NAS Oceana",
                tags: ["Aviation"]
            },
            {
                path: "https://cdn.photos.markwclancy.com/photos/large/SuperHornetBreak.jpg",
                thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/SuperHornetBreak.jpg",
                caption: "A VFA-105 'Gunslingers' F/A-18E Super Hornet in the overhead break",
                tags: ["Aviation"]
            },
            {
                path: "https://cdn.photos.markwclancy.com/photos/large/CedarRidge.jpg",
                thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/CedarRidge.jpg",
                caption: "Cedar Ridge on the South Kaibab Trail in the Grand Canyon",
                tags: ["Landscape"]
            },
            {
                path: "https://cdn.photos.markwclancy.com/photos/large/GrandCanyonRocks.jpg",
                thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/GrandCanyonRocks.jpg",
                caption: "A hiker's rendition of the Grand Canyon landscape",
                tags: ["Landscape"]
            },
            {
                path: "https://cdn.photos.markwclancy.com/photos/large/GrandCanyon.jpg",
                thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/GrandCanyon.jpg",
                caption: "The Mighty Colorado River",
                tags: ["Landscape"]
            },
            {
                path: "https://cdn.photos.markwclancy.com/photos/large/IADMoon.jpg",
                thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/IADMoon.jpg",
                caption: "Moonrise over Washington Dulles",
                tags: ["Aviation", "Cityscape"]
            },
            {
                path: "https://cdn.photos.markwclancy.com/photos/large/Chinooks.jpg",
                thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/Chinooks.jpg",
                caption: "A pair of CH-47 Chinooks performing a flyover of the National Mall",
                tags: ["Aviation", "Cityscape"]
            },
            {
                path: "https://cdn.photos.markwclancy.com/photos/large/BostonFilm.jpg",
                thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/BostonFilm.jpg",
                caption: "Sailboats on the Charles",
                tags: ["Cityscape"]
            },
            {
                path: "https://cdn.photos.markwclancy.com/photos/large/MaceHighAlphaPass.jpg",
                thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/MaceHighAlphaPass.jpg",
                caption: "Thunderbird 5 performing the high alpha pass",
                tags: ["Aviation"]
            },
            {
                path: "https://cdn.photos.markwclancy.com/photos/large/CoastGuardDemo.jpg",
                thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/CoastGuardDemo.jpg",
                caption: "A U.S. Coast Guard MH-65 crew perforing a SAR demo",
                tags: ["Aviation"]
            },
            {
                path: "https://cdn.photos.markwclancy.com/photos/large/VC25.jpg",
                thumbnail: "https://cdn.photos.markwclancy.com/photos/thumbnails/VC25-thumbnail.jpg",
                caption: "A U.S. Air Force VC-25A perfoming a flyover of Washington, D.C.",
                tags: ["Aviation"]
            },
    ]
}